<template>
    <div class="card">
        <div class="card-header bg_head_panel">
        <h6 class="card-title font-weight-semibold">Atur Preferensi Obat</h6>
        </div>  
        <validation-observer
            v-slot="{ handleSubmit }" ref="VForm"
        >
            <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
            <div class="card-body p-3">
                <div class="row g-2">
                <div class="col-md-3">
                    <div class="result_tab">
                    <h4>Poli Layanan</h4>
                    <p>{{row.mpo_name||"-"}}</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="result_tab">
                    <h4>Dokter</h4>
                    <p>{{row.bu_full_name||"-"}}</p>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-9">
                    <label for="cth. Racikan Amlodipin">Atur Obat<strong class="text-danger">*</strong></label>
                    <table class="table table-bordered table-striped table-sm">
                    <thead>
                        <tr>
                        <th width="48">#</th>
                        <th>Nama Obat</th>
                        <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in row.mro_preferensi" :key="k">
                            <td>{{k+1}}</td>
                            <td>
                                <v-select placeholder="Pilih Preferensi" v-model="row.mro_preferensi[k]" :options="getMasterObat(row.mro_preferensi,k)" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                
                                <VValidate 
                                    :name="'Preferensi #'+(k+1)" 
                                    v-model="row.mro_preferensi[k]" 
                                    :rules="{required : 1}"
                                />
                            </td>
                            <td>
                                <a href="javascript:;" class="list-icons-item"
                                @click="row.mro_preferensi.splice(k,1)"
                                data-toggle="tooltip" data-placement="top" title="Delete"><i
                                    class="icon-bin"></i></a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                        <td colspan="3" class="text-center">
                            <a href="javascript:;" @click="row.mro_preferensi.push(null)" class="btn btn-outline-info">
                            <i class="icon-plus2 align-middle mr-1"></i>
                            <span class="align-middle">Tambah Obat</span>
                            </a>
                        </td>
                        </tr>
                    </tfoot>
                    </table>
                </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex">
                <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mObat:Array,
  },
  methods: {
    toValidate(val){
      return {...val}
    },
    
    getMasterObat(data,k){
        const sldMenu = _.map(_.filter(data||[], (v2, k2)=>{
            return k2!=k
        }), v2=>{
            return v2
        })
        return _.filter(this.mObat, v2=>{
            return sldMenu.indexOf(v2.value)<=-1
        })
    },
  },
}
</script>